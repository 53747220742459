.actions .ant-typography {
    padding: 2px 10px !important;
    border: 1px solid #01bd50 !important;
    border-radius: 20px;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 8px;

    &:hover {
        background-color: #01bd50;
        color: #fff;
    }

    &.ant-typography-danger {
        border: 1px solid #ff4d4f !important;

        &:hover {
            background-color: #ff4d4f !important;
            color: #fff;
        }
    }
}
