@import 'variables';

// Button
.ant-btn:not(.ant-btn-icon-only) {
    padding: 8px 16px;
    text-transform: uppercase;
    height: auto;
    width: auto;
    line-height: 1;
    font-weight: 600;
    font-size: 15px;
}

.ant-btn:focus-visible {
    outline: none !important;
}
