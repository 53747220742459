.switches {
    padding: 15px 27px;
}

.switch {
    &:not(:last-child) {
        margin-bottom: 0.5em;
    }
    display: flex;
    gap: 0.25em;
    align-items: center;
}
