@import 'variables';
@import 'btn';
@import 'forms';
@import 'actions';

*,
::after,
::before {
    box-sizing: border-box;
}

html,
body {
    width: 100%;
    height: 100%;
}

div#root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    > .ant-app {
        height: 100%;
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.text-primary {
    color: var(--primary) !important;
}

/* General */
main {
    position: relative;
}

.w-2rem {
    width: 2rem;
}

.w-100 {
    width: 100%;
}

.min-h-100 {
    min-height: 100vh;
}

.gg-small {
    clear: both;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 22px;
}

/* Components */
.ant-table {
    .ant-table-thead {
        th {
            text-transform: uppercase;
        }
    }
}

.ant-card {
    .ant-card-body {
        h2.ant-typography {
            margin-top: 0;
            text-transform: uppercase;
            color: $primary;
            font-size: 20px;
        }
    }

    &.no-head-border .ant-card-head {
        border-bottom: none !important;
    }
}

// Sider
.ant-menu .ant-menu-item-group-title {
    padding: 8px 8px !important;
}

/* Simulated */
.viggo-required {
    &::before {
        display: inline-block;
        margin-inline-end: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
    }
}

.text-nowrap {
    text-wrap: nowrap;
}

.text-danger {
    color: $danger;
}

// Drawer
.ant-drawer .ant-drawer-title {
    font-size: 18px !important;
    font-weight: bold !important;
}

// Table
.ant-table {
    border: 1px solid var(--border);
    border-radius: 8px !important;

    .ant-table-content {
        table {
            .ant-table-thead {
                background: var(--gray);

                > tr {
                    .ant-table-cell {
                        padding: 8px 12px;
                        color: var(--dark);
                    }
                }
            }

            .ant-table-tbody {
                tr {
                    .ant-table-cell {
                        padding: 8px 12px;
                        border-bottom: 1px solid var(--border);
                        background: var(--bg-alt) !important;
                        color: var(--text-color);

                        &.ant-table-cell-row-hover {
                            background: var(--gray) !important;
                        }
                    }

                    &:last-child {
                        .ant-table-cell {
                            border-bottom: none;

                            &:first-child {
                                border-end-start-radius: 8px;
                            }

                            &:last-child {
                                border-end-end-radius: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Modal
.ant-modal-root {
    .ant-modal-mask {
        background-color: rgba(0, 0, 0, 0.55);
        backdrop-filter: blur(4px);
        [data-theme='dark'] & {
            background-color: rgba(255, 255, 255, 0.15);
        }
    }
}

// Pages
.zone-chart {
    main {
        padding: 0;
    }
}
