@import 'scss/variables';

.container {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    height: 300px;
}

:export {
    teamColorFill: $team-color-fill;
    teamColorStroke: $team-color-stroke;

    roleColorFill: $role-color-fill;
    roleColorStroke: $role-color-stroke;

    lightBg: $light-bg;
    darkBg: $dark-bg;
}
