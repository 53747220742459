@import 'scss/variables';

.dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 4px;
    border: 1px solid;
}

:export {
    teamColorFill: $team-color-fill;
    teamColorStroke: $team-color-stroke;

    roleColorFill: $role-color-fill;
    roleColorStroke: $role-color-stroke;

    lightBg: $light-bg;
    darkBg: $dark-bg;
}
