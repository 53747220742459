.form-control {
    padding-bottom: 0;
    margin-bottom: 12px;

    > label {
        padding-bottom: 8px;
        display: inline-block;
        vertical-align: top;
    }

    .ant-select {
        width: 100%;
        height: 45px;
    }

    /* The flag in ant-phone-input is a select too. */
    .ant-phone-input-wrapper .ant-select {
        width: auto;
    }

    .ant-form-item-control-input {
        min-height: 45px;
    }

    .ant-input {
        padding: 10px 12px;
    }

    & > .ant-form-item-row {
        flex-direction: column;
    }

    .ant-form-item-control {
        min-height: auto;
    }

    .ant-form-item-label {
        text-align: left;
    }

    &.ant-form-item-with-help {
        margin-bottom: 24px;
    }

    .ant-form-item-horizontal {
        margin: 12px 0;

        .ant-form-item-label {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

.ant-typography-edit {
    outline: none !important;
}
