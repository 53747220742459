.TeamRow {
    :global {
    }
}

.TeamChart {
    // height: 100%;
    :global {
        .ant-card-body {
            padding: 0;
            .card-header {
                padding: 1rem;
                border-bottom: 1px solid var(--border);
                h2 {
                    margin: 0;
                    padding: 0;
                    color: var(--dark);
                    font-size: 1.1rem;
                    font-weight: 700;
                }
            }
            .card-body {
                padding: 1rem;
            }
            .description {
                p {
                    margin: 0 0 1rem;
                    color: var(--text-color);
                }
            }
        }
    }
}

.TeamDetails {
    :global {
        .ant-card-body {
            padding: 0;
        }
        .ant-card-type-inner > .ant-card-body {
            padding: 8px;
        }

        .ant-tabs-nav-list {
            .ant-tabs-tab {
                padding: 0.75rem 1.3rem;
                margin: 0;
                font-weight: 600;
                font-size: 0.95rem;
            }
        }
        .FixTabBtn {
            position: absolute;
            top: -36px;
            right: 16px;
        }
        .ant-tabs-nav {
            margin: 0;
        }
        .ant-tabs-content {
            padding: 0.5rem 1rem;
        }
    }
}

.MeetingCard {
    margin-top: 1rem;
    margin-bottom: 1rem;
    :global {
        .ant-card-body {
            padding: 0;
            .card-header {
                padding: 0.6rem 1rem;
                border-bottom: 1px solid var(--border);
                h2 {
                    margin: 0;
                    padding: 0;
                    color: var(--dark);
                    font-size: 1.1rem;
                    font-weight: 700;
                }
                .ant-btn {
                    margin-left: auto;
                }
            }
            .card-body {
                padding: 0;
            }
        }
    }
}
.MeetingDetail {
    padding: 1rem;
    border-left: 1px solid var(--border);
    h4 {
        margin: 0 0 1rem;
        font-weight: 700;
        font-size: 1.1rem;
    }
    label {
        margin: 0 0 0.2rem;
        display: inline-block;
        vertical-align: top;
        color: var(--text-color);
        font-size: 0.85rem;
    }
    p {
        margin: 0 0 1rem;
        color: var(--dark);
        font-size: 0.9rem;
    }
}

:export {
}
