.table {
    :global {
        tr th:before {
            width: 0;
        }

        /*
        tbody tr .ant-btn {
            visibility: hidden;
        }
        tbody tr:hover .ant-btn {
            visibility: visible;
        }
         */
    }
}

.buttons {
    white-space: nowrap;
}
