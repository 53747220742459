@import 'scss/variables';

.steps {
    width: auto;
    margin: 0 auto;

    :global {
        .ant-steps-item-description {
            display: block !important;
        }
    }
}

.selector {
    margin: 0 auto;

    &:global(.ant-select) {
        max-width: 250px;
    }
}

.column {
    background-color: $gray;
}

.columnDark {
    :global {
        .ant-card-head {
            border-bottom: none;
        }

        .agenda-item {
            background-color: #22272b; // Trello colors
            border: none;
        }
    }
}

.agendaItem {
    cursor: pointer;
}

/* header */
.header {
    margin: 0 0 1em 0;
}

.title {
    margin: 0 !important;
    text-align: center;
    color: var(--dark) !important;
    font-size: 1.1rem !important;
    font-weight: 700 !important;
}

.lead {
    display: inline-block;
    margin: 0.25em 0 0 0 !important;
    width: 100%;
    text-align: center;
    font-size: 0.85rem !important;
}

/*
.button {
    position: absolute;
    right: 0;
    top: 0;
    margin: 24px 24px 0 0;
}
*/
