$primary: #01bd50;
$white: #fff;

$primary-rgb: 1, 189, 80;

$dark: #181813;
$border: #e9eaed;

$gray: #f7f7f8;
$danger: #ff4d4f;
$danger-rgb: 255, 77, 79;

$gray-dark: #121212;
$border-dark: #252525;
$dark-dark: #fff;

$zone-color-stroke: #696969; // #d3d3d3; // #bd016e;
$zone-color-fill: #d3d3d380; // #fae9ef; //$zone-color-fill: #ca348b; // 80%

$team-color-stroke: #34ca73;
$team-color-fill: #a4e5ba; // #6dd58f;

$role-color-stroke: #3341d3;
$role-color-fill: #7a93ea; // #999FEA; // #0011c8;

$light-bg: white;
$dark-bg: #141414;

:root {
    --primary: #{$primary};
    --primary-rgb: #{$primary-rgb};
    --white: #{$white};
    --dark: #{$dark};

    --text-color: #676f7e;

    --danger: #{$danger};
    --danger-rgb: #{$danger-rgb};

    --bg-alt: #fff;
    --body-bg: #f5f7f9;

    --gray: #{$gray};
    --border: #{$border};

    --input-padding-y: 14px;
    --input-padding-x: 15px;
    --input-border: var(--border);
    --input-rounded: 8px;
    --input-font: 15px;
    --input-bg: #{$white};

    --btn-padding-x: 14px;
    --btn-padding-y: 15px;
    --btn-rounded: 8px;
    --btn-font: 15px;

    --menu-link: #676f7e;
    --menu-link-active: #22252a;
    --menu-active-bg: #fff;
    --menu-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    --menu-heading: #22252Al;
}

[data-theme='dark'] {
    --gray: #{$gray-dark};
    --border: #{$border-dark};
    --dark: #{$dark-dark};

    --text-color: #bfbfbf;

    --bg-alt: #000000;
    --body-bg: #000000;

    --menu-link: #8c8c8c;
    --menu-link-active: #fff;
    --menu-active-bg: #191919;
    --menu-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    --menu-heading: #bfbfbf;
}
