.drawer {
    :global {
        .no-role {
            border: 1px solid rgba(var(--danger-rgb), 0.2);
            padding: 10px 20px;
            border-radius: 5px;
            font-weight: 600;
            background: rgba(var(--danger-rgb), 0.05);
            color: var(--danger);
            margin: 0 0 20px;
        }
        .drawer-avatar {
            display: flex;
            flex-direction: row;
            align-items: center;
            border: 1px solid var(--border);
            border-radius: 10px;
            padding: 10px;
            margin: 0 0 1.5em;
            &:hover {
                border-color: var(--primary);
            }
            .avatar-media {
                width: 80px;
                height: 80px;
                flex: 0 0 80px;
            }
            .avatar-name {
                font-weight: 600;
                color: var(--dark);
                padding: 0 0 0 12px;
                font-size: 1.1rem;
                flex: 1;
            }
        }

        .description {
            color: var(--text-color);
            font-size: 0.9rem;
            line-height: 1.5;
            p {
                margin: 0 0 1rem;
            }

            ul,
            ol {
                padding-left: 1rem;
                padding-top: 0;
                padding-bottom: 1rem;
                margin: 0;
            }
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-weight: 700;
                color: var(--dark);
                margin: 0;
                padding: 0 0 0.8rem;
            }
        }
    }
}

.buttons {
    margin-left: 0.5em;
}
