.node {
    padding: 16px;
    background-color: #fff;
    border: 3px solid #999;
    border-radius: 6px;
    box-sizing: border-box;
    font-size: 24px;
    text-align: center;

    :global {
        .ant-avatar {
            margin-bottom: 4px;
        }

        .name {
            font-size: 24px;
            font-weight: bold;
        }
    }

    &:global(.dark) {
        :global {
            .name,
            .position {
                color: black;
            }

            .ant-avatar {
                background-color: rgba(0, 0, 0, 0.25);
            }
        }
    }
}

.switch {
    position: absolute;
    top: 8px;
    right: 16px + 24px; // <- 24px is the width of the GraphControls
}
