@import 'scss/variables';

.checker {
    :global {
        .met {
            list-style-type: '\2713\0020';
            color: $primary;
        }

        .unmet {
            color: $dark;
        }
    }
}
