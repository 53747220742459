@import 'scss/variables';

.card {
    :global {
        .ant-card-meta {
            margin: 0 0 1em 0;
        }
    }
}

.dangerCard {
    border-color: $danger;
}

.role {
    .ant-card-body {
        display: flex;
        align-items: center;
        height: 100%;
    }
}

.people {
    :global {
        .ant-descriptions-item-label {
            white-space: nowrap;
            display: table-cell;
            width: 1%; /* Ensures it takes only as much space as the content */
            vertical-align: top;
            font-weight: bold;
        }
    }
}

.space {
    :global {
        .ant-space-item,
        .ant-space-item > * {
            width: 100%;
        }
    }
}

:export {
    danger: $danger;
}
