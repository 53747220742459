@import 'scss/variables';

.steps {
    :global {
        .ant-steps {
            background: var(--gray);
            padding: 8px 20px;
            border-radius: 10px;
            border: 1px solid var(--border);
            flex: 0 0 220px;
            width: 200px;
            .ant-steps-item-title {
                text-wrap: nowrap;
                color: var(--dark);
                font-size: 15px;
                font-weight: 600;
            }
        }
    }
}
.content {
    flex: 1;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.form {
    flex: 1;
}

.modal {
    .ant-modal-content {
        border-radius: 10px;
        padding: 0;
        border: 1px solid var(--border);
        background-color: var(--bg-alt);
    }
    .ant-modal-header {
        padding: 15px 20px;
        border-bottom: 1px solid var(--border);
        background-color: var(--bg-alt);
    }
    .ant-modal-body {
        padding: 20px;
    }
}

.button {
    &:focus-visible {
        outline: 1px dashed $primary !important;
    }
}
