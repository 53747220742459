.page {
    max-width: 1000px;
    margin: 0 auto;
    :global {
        .ant-table-wrapper .ant-table-container,
        .ant-table-wrapper table,
        .ant-table-tbody,
        .ant-table {
            border-radius: 0 !important;
            border-start-start-radius: 0;
            border-end-end-radius: 0;
        }
        .ant-table-cell {
            border-end-start-radius: 0 !important;
            border-end-end-radius: 0 !important;
        }
        .form-control {
            padding-bottom: 0;
            .ant-form-item-row {
                flex-direction: column;
            }
            .ant-form-item-control {
                min-height: auto;
            }
            .ant-form-item-label {
                text-align: left;
            }
        }
        .add-field {
            padding-top: 25px;
            padding-bottom: 25px;
            .ant-btn {
                width: 100%;
                border: 1px dashed var(--primary);
                color: var(--primary);
            }
        }
    }
}

.createButton {
    margin: 1em 0;
}

.btnBar {
    border-top: 1px solid var(--border);
    width: 100%;
    padding-top: 24px;
}

.sectionTitle {
    font-size: initial;
    line-height: initial;
}

.fieldDisplay {
    display: flex;
    gap: 8px;
    align-items: center;
}
