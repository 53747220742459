@import 'scss/variables';

.page {
    :global {
        .reset-link {
            display: inline-block;
            text-align: center;
            padding: 0 12px;
            font-size: 16px;
            border-radius: 10px;
            span {
                color: var(--primary);
                font-weight: 500;
            }
        }
    }
}
