.page {
    :global {
        hr {
            margin-block-start: 2em;
        }
        h4 {
            margin-block-end: 0.25em;
        }
        .ant-btn {
            margin-top: 1em;
        }
    }
}
