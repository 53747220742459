@import 'scss/variables';

.page {
    min-height: 100vh;

    &.dark {
        background-color: #181813;
    }

    :global {
        .col-left {
            position: relative;
            @media (max-width: 767px) {
                display: none;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top right;
                position: absolute;
                top: 0;
                left: 0;
            }
            .login-text {
                position: absolute;
                left: 0;
                bottom: 0;
                color: var(--white);
                max-width: 100%;
                padding: 50px;
                width: 450px;
                box-sizing: border-box;
                h1 {
                    margin: 0;
                    padding: 0 0 25px;
                    font-size: 80px;
                    font-weight: 700;
                    line-height: 1;
                    @media (max-width: 991px) {
                        font-size: 60px;
                    }
                }
                p {
                    font-size: 16px;
                    margin: 0;
                    padding: 0;
                }
            }
        }

        .col-right {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 75px 20px 20px 20px;
            position: relative;

            .login-logo {
                position: absolute;
                top: 20px;
                left: 20px;
                img {
                    width: auto;
                    height: 50px;
                }
            }

            .ant-form {
                max-width: 390px;
                width: 100%;
            }
            h1 {
                margin: 0;
                padding: 0 0 30px;
                font-weight: 700;
            }
            .ant-input-outlined {
                padding: var(--input-padding-y) var(--input-padding-x);
                border-radius: var(--input-rounded);
                font-weight: 400;
                font-size: var(--input-font);
                line-height: 100%;
                .ant-input-prefix {
                    margin-right: 10px;
                }
            }
            .ant-btn {
                padding: var(--btn-padding-y) var(--btn-padding-x);
                height: auto;
                border-radius: var(--btn-rounded);
                font-size: var(--btn-font);
                text-transform: uppercase;
                font-weight: 600;
                width: 100%;
            }
        }
    }
}

.link {
    float: right;
}
