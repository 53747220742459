@import 'scss/variables';

.table {
    background-color: transparent;

    :global {
        .ant-table-content {
            table {
                .ant-table-tbody {
                    tr {
                        .ant-table-cell {
                            //border-bottom: 1px solid var(--border);
                            //border-radius: 0 !important;
                            color: var(--dark);
                            font-weight: 500;
                            padding: 12px 12px;

                            &:last-child {
                                width: 1%;
                                white-space: nowrap;
                                text-align: right;
                            }
                        }

                        &:last-child {
                            .ant-table-cell {
                                //border-bottom: none;
                            }
                        }
                    }
                }
            }
        }

        .ant-table-cell {
            .ant-tag {
                background: var(--gray);
                padding: 2px 16px;
                margin: 0;
                border-radius: 30px;
                color: var(--dark);
                border: 1px solid var(--border);
            }

            /*
            .t-icon {
                display: flex;
                gap: 8px;
                padding-bottom: 5px;
                align-items: center;

                &:before {
                    content: "";
                    width: 16px;
                    height: 16px;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

            .text {
                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.66663 5.16669V3.16669H13.3333V5.16669' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6 13.8333H10' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 3.16669V13.8334' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                }
            }

            .single-select {
                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.3333 1L4.99996 8.33333L1.66663 5' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                }
            }

            .multi-select {
                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 7.33335L8 9.33335L14.6667 2.66669' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M14 8V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H10.6667' stroke='black' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                }
            }

            .one-to-one {
                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.9375 10.5H5.875C5.94375 10.5 6 10.4437 6 10.375V5.625C6 5.55625 5.94375 5.5 5.875 5.5H4.9375C4.86875 5.5 4.8125 5.55625 4.8125 5.625V10.375C4.8125 10.4437 4.86875 10.5 4.9375 10.5ZM8 9.71875C8.34531 9.71875 8.625 9.43906 8.625 9.10938C8.625 8.74844 8.34531 8.46875 8 8.46875C7.65469 8.46875 7.375 8.74844 7.375 9.10938C7.375 9.43906 7.65469 9.71875 8 9.71875ZM8 7.53125C8.34531 7.53125 8.625 7.25156 8.625 6.92188C8.625 6.56094 8.34531 6.28125 8 6.28125C7.65469 6.28125 7.375 6.56094 7.375 6.92188C7.375 7.25156 7.65469 7.53125 8 7.53125Z' fill='black'/%3E%3Cpath d='M13.75 1.75H2.25C1.97344 1.75 1.75 1.97344 1.75 2.25V13.75C1.75 14.0266 1.97344 14.25 2.25 14.25H13.75C14.0266 14.25 14.25 14.0266 14.25 13.75V2.25C14.25 1.97344 14.0266 1.75 13.75 1.75ZM13.125 13.125H2.875V2.875H13.125V13.125Z' fill='black'/%3E%3Cpath d='M10.125 10.5H11.0625C11.1313 10.5 11.1875 10.4437 11.1875 10.375V5.625C11.1875 5.55625 11.1313 5.5 11.0625 5.5H10.125C10.0562 5.5 10 5.55625 10 5.625V10.375C10 10.4437 10.0562 10.5 10.125 10.5Z' fill='black'/%3E%3C/svg%3E%0A");
                }
            }

            .one-to-many {
                &:before {
                    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.8 3.8C12.8 4.13137 12.5314 4.4 12.2 4.4C11.8686 4.4 11.6 4.13137 11.6 3.8C11.6 3.46863 11.8686 3.2 12.2 3.2C12.5314 3.2 12.8 3.46863 12.8 3.8ZM10.5085 4.41688C10.216 4.44002 9.85658 4.49486 9.50786 4.62476C8.93978 4.83638 8.444 5.22524 8.28872 6.01565C8.09492 7.00232 7.54806 7.62091 6.93036 8C7.54806 8.37908 8.09492 8.99768 8.28872 9.98438C8.444 10.7748 8.93978 11.1636 9.50786 11.3752C9.85658 11.5051 10.216 11.56 10.5085 11.5831C10.7603 10.8928 11.4226 10.4 12.2 10.4C13.1941 10.4 14 11.2059 14 12.2C14 13.1941 13.1941 14 12.2 14C11.4109 14 10.7404 13.4923 10.4974 12.7857C10.1173 12.7605 9.60248 12.691 9.089 12.4998C8.24774 12.1864 7.36849 11.5252 7.11125 10.2156C6.95599 9.42524 6.46023 9.03638 5.89212 8.82476C5.74783 8.771 5.6017 8.73008 5.45925 8.699C5.18638 9.34592 4.54622 9.8 3.8 9.8C2.80589 9.8 2 8.99414 2 8C2 7.00589 2.80589 6.2 3.8 6.2C4.54622 6.2 5.18638 6.65409 5.45925 7.30101C5.6017 7.26989 5.74783 7.22899 5.89212 7.17525C6.46022 6.96362 6.95599 6.57477 7.11125 5.78436C7.36849 4.47477 8.24774 3.81362 9.089 3.50025C9.60248 3.30896 10.1173 3.23953 10.4974 3.21433C10.7404 2.50774 11.4109 2 12.2 2C13.1941 2 14 2.80589 14 3.8C14 4.79411 13.1941 5.6 12.2 5.6C11.4226 5.6 10.7603 5.10717 10.5085 4.41688ZM4.4 7.99941C4.39968 7.66831 4.13118 7.4 3.8 7.4C3.46863 7.4 3.2 7.66863 3.2 8C3.2 8.33138 3.46863 8.6 3.8 8.6C4.13118 8.6 4.39968 8.33168 4.4 8.0006V7.99941ZM12.2 12.8C12.5314 12.8 12.8 12.5314 12.8 12.2C12.8 11.8686 12.5314 11.6 12.2 11.6C11.8686 11.6 11.6 11.8686 11.6 12.2C11.6 12.5314 11.8686 12.8 12.2 12.8Z' fill='black'/%3E%3C/svg%3E%0A");
                }
            }
             */
        }
    }
}

:export {
}
