.dropzone {
    :global {
        section {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 2px dashed #eeeeee;
            border-radius: 2px;
            background-color: #fafafa;
            color: #bdbdbd;
            outline: none;
            transition: border 0.24s ease-in-out;
            cursor: pointer;

            & > div {
                // This is the clickable element.
                padding: 20px;
                width: 100%;
                height: 100%;
                text-align: center;
            }
        }
    }
}

.crop {
    :global {
        img {
            max-width: 100%;
            height: auto;
        }
    }
}

.preview {
    :global {
        img {
            width: 472px;
            height: 472px;
            max-width: 100%;
        }
    }
}

.avatar {
    cursor: pointer;
}
