.sider {
    background: transparent;
    overflow-y: auto;
    min-height: 100vh;

    :global {
        .ant-menu {
            font-size: 15px;
            font-weight: 500;
            background: transparent;
            padding: 0 15px;

            .ant-menu-item-divider {
                margin: 0 -15px;
            }

            .ant-menu-item-group-title {
                color: var(--dark);
            }

            .ant-menu-item {
                margin: 0 0 4px;
                padding: 0 12px !important;
                width: 100%;

                a {
                    color: var(--menu-link);
                }
            }

            .ant-menu-item-active,
            .ant-menu-item-hover,
            .ant-menu-item-selected {
                background: var(--menu-active-bg) !important;
                box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
                color: var(--menu-link-active);

                a {
                    color: var(--menu-link-active);
                }
            }
        }
    }
}

.logo {
    display: flex;
    padding: 20px 10px;

    :global {
        img {
            width: 120px;
        }
    }
}
