.tab {
    :global {
        .ant-row {
            .ant-col {
                display: flex;
                margin: 0.5rem 0;
                > .ant-typography {
                    height: 100%;
                    width: 100%;
                    display: flex;
                }
            }
        }
    }
}

.card {
    border: 1px solid var(--border);
    width: 100%;
    padding: 1rem;

    :global {
        .avatar {
            width: 50px;
            height: 50px;
            flex: 0 0 50px;
            background-color: rgba(var(--primary-rgb), 0.15);
            color: var(--primary);
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            border-radius: 50%;
        }
        .card-info {
            padding-left: 1rem;
            padding-right: 1rem;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .designation {
            color: var(--text-color);
            font-size: 0.85rem;
        }
        .ant-card-body {
            padding: 0;
        }
        h3 {
            font-size: 1em;
            margin: 0;
            color: var(--dark);
        }
    }
}
