@import 'scss/variables';

.main {
    background: var(--bg-alt);
    margin: 10px 10px 10px 0;
    border-radius: 15px;
    border: 1px solid var(--border);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.layout {
    background-color: var(--body-bg);
    width: 100%;
    height: 100%;
    :global {
    }
}

.content {
    padding: 18px;
    flex: 1;
    overflow-y: auto;
}
