@import 'scss/variables';

.page {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    :global {
        .auth-container {
            max-width: 400px;
            width: 100%;
            padding: 20px;
        }

        .auth-help-text {
            text-align: center;
            color: #676f7e;
            font-size: 14px;
            font-weight: 400;
            a {
                color: var(--primary);
                text-decoration: none;
            }
        }

        h1 {
            margin: 0;
            padding: 0 0 30px;
            font-weight: 700;
            text-align: center;
        }

        .ant-form-item {
            margin-bottom: 2rem;
        }

        .ant-input-outlined {
            padding: var(--input-padding-y) var(--input-padding-x);
            font-weight: 400;
            font-size: var(--input-font);
            line-height: 100%;

            .ant-input-prefix {
                margin-right: 10px;
            }
        }

        .ant-btn {
            padding: var(--btn-padding-y) var(--btn-padding-x);
            height: auto;
            border-radius: var(--btn-rounded);
            font-size: var(--btn-font);
            text-transform: uppercase;
            font-weight: 600;
            width: 100%;
        }
    }
}

.logo {
    display: flex;
    justify-content: center;
    padding: 0 0 20px;

    :global {
        img {
            width: auto;
            height: 65px;
        }
    }
}
