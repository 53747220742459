$height: 30px;

.line {
    height: $height;
    width: 100%;
    max-width: 200px;

    :global {
        .ant-select-selection-item {
            padding-inline-end: 0 !important;
            height: $height;
        }
        .ant-select-item-option-content {
            height: $height;
        }
    }
}

.dropdown {
    :global {
        .ant-select-item-option-content {
            height: $height;
        }
    }
}

.marker {
    max-width: 100px;
}

.controls {
    position: absolute;
    right: 8px;
    top: 8px;
}
